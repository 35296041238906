export const INIT_ORDER = "INIT_ORDER";
export const SET_ORDER_QTY = "SET_ORDER_QTY";
export const SET_PRE_CANCEL_ORDER = "SET_PRE_CANCEL_ORDER";
export const SET_CANCEL_ORDER = "SET_CANCEL_ORDER";
export const SET_ORDER_SUGGESTION = "SET_ORDER_SUGGESTION";
export const SET_END_ORDER_PROCESS = "SET_END_ORDER_PROCESS";
export const SET_ORDER_SUGGESTION_QTY = "SET_ORDER_SUGGESTION_QTY";
export const ORDER_WITH_MODIFIERS = "ORDER_WITH_MODIFIERS";
export const RESET_ORDER_AI = "RESET_ORDER_AI";
export const SET_STAFF_TIP = "SET_STAFF_TIP";
export const SET_ON_TIP_PROCESS = "SET_ON_TIP_PROCESS";
export const SELECT_SERVICE_OPTIONS = "SELECT_SERVICE_OPTIONS";
export const SET_PRODUCT_WITH_MODIFIERS = "SET_PRODUCT_WITH_MODIFIERS";
