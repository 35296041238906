import axios from "axios";
import { generateProductFinderPrompt, isStringifiedJSON } from "./helpers";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const siteName = process.env.REACT_APP_RESTAURANT_NAME;
const rubyAIUri = process.env.REACT_APP_RUBY_AI_URI;
const rubyConvoUri = process.env.REACT_APP_RUBY_CONVO_URI;
const aiBiAPI = process.env.REACT_APP_AIBI_URI;
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const openAIKey = process.env.REACT_APP_OPENAI_API_KEY;
export const updateConversations = async (
  userMessage,
  rasaResponse,
  senderId,
  intentName = "ruby_order_process",
  attempt = 0
) => {
  const lastName = sessionStorage.getItem("lastName") || "AI";
  const firstName = sessionStorage.getItem("firstName") || "SICC";
  const middleName = sessionStorage.getItem("middleName") || "";
  try {
    await axios.put(`${rubyConvoUri}/conversations/save/${senderId}`, {
      userMessage,
      rasaResponse,
      intentName,
    });

    return;
  } catch (error) {
    if (error?.message === "Request failed with status code 404") {
      if (attempt < 3) {
        setTimeout(
          () =>
            updateConversations(
              userMessage,
              rasaResponse,
              senderId,
              (attempt += 1)
            ),
          2000
        ); // Retry after 2 seconds
      } else {
        console.error(error, "API call failed after 3 attempts");
      }
    }

    return;
  }
};

export const updateConversationWithProduct = async (
  userMessage,
  rasaResponse,
  productDetails,
  senderId,
  attempt = 1
) => {
  try {
    await axios.put(
      `${rubyConvoUri}/conversations/save-with-product/${senderId}`,
      {
        userMessage,
        rasaResponse,
        productDetails,
      }
    );
    return;
  } catch (error) {
    if (attempt < 3) {
      setTimeout(
        () =>
          updateConversations(userMessage, rasaResponse, senderId, attempt + 1),
        2000
      ); // Retry after 2 seconds
    } else {
      console.error(error, "API call failed after 3 attempts");
    }
    return;
  }
};
export const getItemModifiers = async (item) => {
  try {
    const { data } = await axios.get(
      `${apiBaseURL}/items/mods/${siteId}/${item.itemid}?server_id=${serverId}`
    );
    return data;
  } catch (error) {
    console.log("getItemModifiers error", error);
    return [];
  }
};

export const processItemsWithModifiers = async (items = []) => {
  // deep clone the item array
  const list = items.map((item) => ({ ...item }));
  // Create an array of promises for fetching item modifiers
  const promises = list.map(async (item) => {
    try {
      const modifiers = await getItemModifiers(item);

      const newData = {
        ...item,
        modifiers: [...modifiers],
      };

      return newData;
    } catch (error) {
      console.log(error, "err");
      // Return the item with an empty `modifiers` array if an error occurs
      return {
        ...item,
        modifiers: [],
      };
    }
  });

  // Wait for all promises to resolve and return the result
  return Promise.all(promises);
};

export const fetchBIAPI = async (availableProducts, userInput) => {
  const productIDsAndNames = availableProducts.map((item) => ({
    itemId: item.ItemID,
    itemName: item.ItemName,
    itemDecription: item.ItemDescription,
  }));

  try {
    const res = await openAICompletion([
      {
        role: "system",
        content: generateProductFinderPrompt(
          siteName,
          JSON.stringify(productIDsAndNames, null, 2)
        ),
      },
      {
        role: "user", // The user asking the question or making the request
        content: userInput,
      },
    ]);
    if (!isStringifiedJSON(res)) {
      return {
        items: [],
        message: res,
      };
    }

    const parsedData = JSON.parse(res);
    const items =
      parsedData?.items?.map((item) => {
        const findItem = availableProducts.find(
          (product) => product.ItemID === item.itemid
        );
        return {
          ...findItem,
          ...item,
        };
      }) || [];

    let newData = {};
    if (items.length > 0) {
      newData = {
        message: parsedData.message,
        items: items,
      };
    } else {
      newData = { message: parsedData.message, items: [] };
    }

    return newData;
  } catch (error) {
    console.log(error, "err");
    return {
      items: [],
      message: "Something went wrong with our completion service",
    };
  }
};

export const openAICompletion = async (input = []) => {
  try {
    const { data } = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-4o", // Correct model selection (ensure this is available in your context)
        messages: input,
        max_tokens: 1500, // Adjust based on how detailed you want the response
        temperature: 0, // Set the level of creativity/randomness
        top_p: 1.0, // Sampling method
        frequency_penalty: 0, // Optional: Modify if you want to avoid repeated phrases
        presence_penalty: 0, // Optional: Modify if you want to encourage new information
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${openAIKey}`,
        },
      }
    );

    return (
      data?.choices[0]?.message?.content ??
      "I am sorry, I am not able to answer that."
    );
  } catch (error) {}
};
