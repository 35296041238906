import React, { useState } from "react";
import { useMediaQuery, Dialog, Box, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { memo } from "react";
import { useSelector } from "react-redux";
import ProductWithModifiers from "./ProductWithModifiers";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "wrap",
    height: "auto",
    gridTemplateColumns: "fit-content(300px) fit-content(300px) 1fr",
    transform: "translateZ(0)",
    padding: "0rem 10px",
    backgroundColor: "white",
    marginRight: "auto !important",
    marginLeft: "auto !important",
    justifyContent: "center",
  },
  toolbar: theme.mixins.toolbar,
  dialogTitleBar: {
    margin: 0,
    padding: theme.spacing(2),
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MultipleProductWithModifiersDialog = ({
  open,
  handleClose,
  handleCheckout,
}) => {
  const classes = useStyles();
  const {
    rubyOrder: { productsWithModifiers },
  } = useSelector((state) => state);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleClose}
        fullWidth
        maxWidth={"md"}
        fullScreen={isMobile}
        style={{ borderRadius: "0px" }}
        scroll="paper"
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {productsWithModifiers.map((product, index) => (
                <Tab label={product.ItemName} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Box>
          {productsWithModifiers.map((product, index) => {
            const productMods = [...(product?.modifiers ?? [])];

            return (
              <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
              >
                <ProductWithModifiers
                  product={product}
                  handleClose={handleCheckout}
                  requiredMods={productMods
                    ?.filter((mod) => mod.ModifierMinSelect > 0)
                    ?.map((mod) => mod.ItemID)}
                />
              </div>
            );
          })}
        </Box>
      </Dialog>
    </>
  );
};

export default memo(MultipleProductWithModifiersDialog);
