import React from "react";
import {
  Typography,
  Grid,
  Button,
  Box,
  Toolbar,
  Drawer,
  IconButton,
} from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input } from "../../../components";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { setTip } from "../../../redux/cartReducer/actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-simple-keyboard/build/css/index.css";

import Fab from "@mui/material/Fab";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FontSizeChanger from "react-font-size-changer";

const tableNumber = sessionStorage.getItem("table");
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const TipsDrawerSL = ({ open, setOpen }) => {
  const { totalPriceItems } = useSelector((state) => state.cart);
  const tipsConstant = [0.1, 0.15, 0.2, 0.25];
  const dispatch = useDispatch();
  const initialValues = {
    tip: 0,
  };

  const validationSchema = Yup.object({
    tip: Yup.mixed(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { setValue } = methods;

  const handleSubmit = (d) => {
    // console.log(d);
    dispatch(setTip(d.tip));
    setOpen(false);
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor={"bottom"}>
      {parseInt(enableKiosk) === 0 && (
        <FontSizeChanger
          targets={["#target .content"]}
          onChange={(element, newValue, oldValue) => {
            console.log(element, newValue, oldValue);
          }}
          options={{
            stepSize: 2,
            range: 5,
          }}
          customButtons={{
            up: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 237.5,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#367c2b" }}
                    >
                      <AddCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
            down: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 180,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#765F00" }}
                    >
                      <RemoveCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
          }}
        />
      )}
      <Box id="target" padding="1rem" height="70vh" position="relative">
        <Typography className="content" style={{ fontWeight: 700 }}>
          Tips
        </Typography>
        <Toolbar />
        <Grid container spacing={2}>
          {tipsConstant.map((tip) => {
            return (
              <Grid key={tip} item xs={3}>
                <Button>
                  <Box
                    display="flex"
                    //border="1px solid black"
                    //borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    padding="0rem"
                    width="60px"
                    height="60px"
                    onClick={() =>
                      setValue("tip", (tip * totalPriceItems).toFixed(2))
                    }
                  >
                    <Typography className="content">{tip * 100} %</Typography>
                  </Box>
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Toolbar />
        <Form provider={methods} onSubmit={handleSubmit}>
          <Input label="Amount $" name="tip" />
          <Box
            position="absolute"
            bottom="auto"
            left="0px"
            padding="1rem"
            width="100%"
          >
            <Button className="content" color="primary" fullWidth type="submit">
              Apply Tip
            </Button>
          </Box>
        </Form>
      </Box>
    </Drawer>
  );
};

export default TipsDrawerSL;
