import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Box,
  Toolbar,
  Drawer,
  IconButton,
} from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import { useSelector, useDispatch } from "react-redux";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-time-picker/assets/index.css";
import { setSelectedAddress } from "../../../redux/publicReducer/actions";
import { useHistory, useRouteMatch } from "react-router-dom";
import Select from "react-select";
import { setModeOfPayment } from "../../../redux/cartReducer/actions";
import axios from "axios";

import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from "react-bootstrap";
import "react-simple-keyboard/build/css/index.css";

import Fab from "@mui/material/Fab";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FontSizeChanger from "react-font-size-changer";
import { toast } from "react-toastify";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;

const SelectCreditCardDrawerSL = ({ open, setOpen }) => {
  const [userCards, setUserCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [firstName, setFirstName] = useState(
    sessionStorage.getItem("firstName")
  );
  const [roomNumber, setRoomNumber] = useState(
    sessionStorage.getItem("roomNumber")
  );
  const [mealBalance, setMealBalance] = useState();
  const [mealBalanceDollars, setMealBalanceDollars] = useState();
  const mBal = Math.abs(mealBalanceDollars / 100);
  const mBalance = mBal.toFixed(2);
  const { user } = useSelector((state) => state.public);
  const { selectedPayment } = useSelector((state) => state.cart);
  const { orderTimeSelectValue } = useSelector((state) => state.public);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const loginCustomerNumber = process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER;

  useEffect(() => {
    const url =
      loginCustomerNumber == 1
        ? apiBaseURL +
          "/customer/login-customer-number/" +
          serverId +
          "/" +
          siteId +
          "/" +
          firstName +
          "/" +
          roomNumber
        : apiBaseURL +
          "/customer/login/" +
          serverId +
          "/" +
          siteId +
          "/" +
          storeId +
          "/" +
          firstName +
          "/" +
          roomNumber;
    axios
      .get(url)
      .then((response) => {
        const customer = response.data;
        if (
          customer.FirstName.toLowerCase() === firstName.toLowerCase() &&
          (customer.Address1.toLowerCase() === roomNumber.toLowerCase() ||
            customer.CustomerNumber == roomNumber)
        ) {
          setMealBalance(customer.MealCount);
          setMealBalanceDollars(
            customer.CreditLimit - customer.AvailableCredit
          );
          //change MealDollrar to Mealcount
          dispatch(
            setSelectedAddress({
              first_name: customer.FirstName,
              last_name: customer.LastName,
              phone: customer.Phone,
              email: customer.Email ? customer.Email : "",
              address: customer.Address1,
              address2: customer.Address2,
              city: customer.City,
              zipCode: customer.Zip,
            })
          );
        }
      })
      .catch((error) => {});
  }, []);

  const sendOrder = () => {
    setLoading(true);
    const url =
      apiBaseURL +
      "/postpayment/send_econnect_sl/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId +
      "/" +
      sessionStorage.getItem("order_id");
    axios
      .get(url)
      .then((response) => {
        setLoading(false);
        if (response.data) {
          localStorage.clear();
          sessionStorage.removeItem("order_id");
          sessionStorage.removeItem("table");

          toast.success(`Your Order has been Completed! Thank You.`);
          if (enableRevenueCenter == 1) {
            history.push("/welcome");
          } else {
            history.push("/menu");
          }
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
      {parseInt(enableKiosk) === 0 && (
        <FontSizeChanger
          targets={["#target .content"]}
          onChange={(element, newValue, oldValue) => {
            console.log(element, newValue, oldValue);
          }}
          options={{
            stepSize: 2,
            range: 5,
          }}
          customButtons={{
            up: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 237.5,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#367c2b" }}
                    >
                      <AddCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
            down: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 180,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#765F00" }}
                    >
                      <RemoveCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
          }}
        />
      )}
      <Modal
        show={showModal}
        style={{ zIndex: "99999" }} // corrected style object
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-container"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Success!!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
            }}
            component="h4"
            variant="subtitle1"
          >
            Your Order has been Completed! Thank You.
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Grid container justifyContent="center" className="content">
            <Button
              fullWidth
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              style={{
                textTransform: "none",
                padding: "0rem 1rem",
                borderRadius: "10px",
                border: "1px solid #bebebe",
                backgroundColor: buttonColor,
                fontSize: "large",
                width: "50%",
              }}
              onClick={() => {
                setOpen(false);
                setShowModal(false);
                // setTimeout(() => {
                //   if (enableRevenueCenter == 1) {
                //     history.push("/welcome");
                //   } else {
                //     history.push("/menu");
                //   }
                // }, 500);
              }}
            >
              OK
            </Button>
          </Grid>
        </Modal.Footer>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box id="target" padding="1rem" minHeight="80vh" position="relative">
        <Box height="40px" />
        <Typography
          style={{
            fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
          }}
          className="content"
          component="div"
          align="center"
        >
          Hey {firstName}, your
        </Typography>
        <Typography
          className="content"
          style={{
            fontWeight: 700,
            "text-align": "center",
            fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
          }}
        >
          {/*selectedPayment*/}
        </Typography>
        {userCards.map((card) => {
          return (
            <Box
              borderRadius="10px"
              border="1px solid #bebebe"
              padding="1rem 1rem"
              margin="1rem 0rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              overflow="auto"
              onClick={() => {
                dispatch(setModeOfPayment(card));
                setOpen(false);
              }}
            >
              <Typography className="content">{card.MaskedAccount}</Typography>
            </Box>
          );
        })}

        {selectedPayment == "Meal Credit" && (
          <div>
            <Typography
              component="div"
              align="center"
              style={{
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
              }}
            >
              <b>Meal Credit Available: </b>
              <b>{mealBalance}</b>{" "}
            </Typography>
            <Typography
              component="div"
              align="center"
              style={{
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
              }}
            >
              <b>Current Balance: </b>
              <b>${mBalance}</b>{" "}
            </Typography>
            <br></br>
            <Grid container justifyContent="center" className="content">
              <Button
                fullWidth
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{
                  textTransform: "none",
                  padding: "0rem 1rem",
                  borderRadius: "10px",
                  border: "1px solid #bebebe",
                  backgroundColor: buttonColor,
                  fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                  width: "50%",
                }}
                onClick={() => {
                  sendOrder();
                }}
              >
                Submit Order
              </Button>
            </Grid>
          </div>
        )}
      </Box>
    </Drawer>
  );
};

export default SelectCreditCardDrawerSL;
